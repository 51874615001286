body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.ant-menu {
    line-height: 64px;
}

.content {
    padding: 24px;
    min-height: 280px;
}

h1,
h2,
h3 {
    margin-top: 20px;
}